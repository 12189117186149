<template>
    <div>
        <div class="container-fluid">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue my-4">
                        VISA AND WORK PERMIT SERVICES
                    </h1>
                    <div class="col-12 col-lg-6 text-center mb-5">
                        You want to stay and work in Thailand but don’t know where to start, we will assist you in obtaining your visa and work permit
                    </div>
                    <div class="col-12 col-lg-8">
                        <div class="row">
                            <div v-for="(service, serviceIndex) in services" :key="serviceIndex" class="col-12 col-lg-6 mb-4 mb-lg-0">
                                <h5 class="mb-4">{{ service.title }}</h5>
                                <div v-for="(price, priceIndex) in service.prices" :key="priceIndex">
                                    <div class="mb-2">{{ price.title }}</div>
                                    <h4 class="text-blue mb-4">THB {{ price.price }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fbest_place.svg?alt=media&token=20853424-4848-4747-be4f-5d97fbcd5176"
            },
            services: [
                {
                    title: "Work permit services Pricing",
                    prices: [
                        {title: "New work permit application", price: "10,000.00"},
                        {title: "Extend work permit application", price: "12,000.00"},
                        {title: "Cancel and return work permit", price: "2,500.00"},
                    ]
                },
                {
                    title: "Visa services (Immigration) Pricing",
                    prices: [
                        {title: "One year visa at Immigration (Non Immigrant B)", price: "15,000.00"},
                        {title: "Change passport and stamp visa", price: "2,500.00"},
                        {title: "Cancel visa", price: "2,500.00"},
                    ]
                }
            ]
        }
    }
}
</script>